import * as React from "react";
import { Link } from "gatsby";
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing'
import  Map  from '../components/map';

const unium = () => {
	return(
		<ModalRoutingContext.Consumer>
		{({ modal, closeTo }) => (
			<>
			  <Link 
			  	className="Modal-close unium"
			  	to={closeTo}
			  	state={{noScroll: true }}
			  >
				<span></span>
			  </Link>
			<div className="Work-wrap unium-modal">
				<div class="unum-video-wrap">
					<video autoplay="true" loop="true">
						<source src={'work/unum/unim.mp4' }type="video/mp4" />
					</video>
				</div>
				<div class=""></div>
				<ul className="Work-slides">
					<li class="spacer"></li>
					<li className="Title">
						<h3 className="font-title text-center">Unum Fund</h3>
					</li>
					<li className="Work">
						<div>
						<picture>
								<source srcset={'/work/unum/1/1-a.png'} media="(min-width: 59em)" />
								<source srcset={'/work/unum/1/1-b.png'} media="(min-width: 34em)" />
								<source srcset={'/work/unum/1/1-c.jpg'} media="(max-width: 34em)" />
								<img src={"/work/unum/1/1-a.png"} alt="Unum Fund Work Example" />
							</picture>
						</div>
					</li>
					<li className="Work unum-too">
						<div>
							<Map/>
						</div>
					</li>
					<li className="Work unium-final">
						<div className="unium-goto goto">
							<p>Incubated at Emerson Collective and led by former New Orleans Mayor Mitch Landrieu, E Pluribus Unum is an initiative created to fulfill America’s promise of justice and opportunity for all by breaking down the barriers that divide us by race and class.</p>
							<a target="blank" rel="noreferrer" href="https://dividedbydesign.org/"><p>View Site</p></a>
						</div>
					</li>
				</ul>
		  </div>
		  </>
		)}
	  </ModalRoutingContext.Consumer>
	)
}

export default unium